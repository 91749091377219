.contact-form {
  width: 100%;
  margin-top: 20px;

  

  ul {
    padding: 0;
    margin: 0;

    li {
      padding: 0;
      margin: 0;
      list-style: none;
      margin-bottom: 10px;
      opacity: 0;
      overflow: hidden;
      display: block;
      clear: both;
      position: relative;
      animation: fadeInUp 2s 2s;
      animation-fill-mode: forwards;
    }

    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  input[type='text'],
  input[type='email'] {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
  }

  textarea {
    width: 100%;
    border: 0;
    background: #115173;
    height: 50px;
    font-size: 16px;
    color: #fff;
    padding: 20px;
    box-sizing: border-box;
    min-height: 150px;
  }

  .flat-button {
    color: #ffd700;
    font-size: 11px;
    letter-spacing: 3px;
    text-decoration: none;
    padding: 8px 10px;
    border: 1px solid #ffd700;
    float: left;
    border-radius: 4px;
    background: 0 0;
    text-transform: uppercase;
    float: right;
    text-align: center;
    margin-right: 10px;

    &:hover {
      background-color: #ffd700;
      color: #115173;
      cursor: pointer;
    }
  }


}

.contact-detail{
  padding: 10px;
  ul {
    padding: 0;
    list-style-type: none;
}
}

.info-wrap {
  background: rgba(8, 253, 216, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  width: 53%;
  height: 115%;
  margin-top: -3%;
  position: relative;
}

.info-map {
  width: 60%;
  max-height: 70%;
  background: #115173;
  z-index: 999999;
  padding: 20px;
  color: #ffd700;
  font-family: 'Helvetica';
  font-size: 17px;
  font-weight: 300;
  opacity: 0;
  animation: fadeIn 1s 1.5s forwards, shake 10s ease infinite;
  li {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  li .icon {
    margin-right: 12px;
    color: #ffd700;
  }

  .mobile-description{
    display: none;
  }
}

//Animation


@keyframes shake {
  0% { transform: rotate(0); }
  2%, 18% { transform: rotate(-5deg); }
  4%, 16% { transform: rotate(5deg); }
  6%, 14% { transform: rotate(-5deg); }
  8%, 12% { transform: rotate(5deg); }
  10% { transform: rotate(0); }
  20%, 100% { transform: rotate(0); } /* Remain in the initial state for the rest of the duration */
}
//Animation



@media screen and (max-width: 720px) {
  .info-wrap {
    display: block;
    width: 90%;
    height: 70%;
    float: none;
    margin: 0 auto;
    background-color:#022c43;
  }

  .info-map {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 0;
    padding: 0;

  }

  .info-map p{
    display: none;
  }
  .mobile-description{
    display: block;
  }

  

}